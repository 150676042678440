export default {
    // 'app.setting.pagestyle': 'Page style setting',
    // 'app.setting.pagestyle.light': 'Light style',
    // 'app.setting.pagestyle.dark': 'Dark style',
    // 'app.setting.pagestyle.realdark': 'RealDark style',
    // 'app.setting.themecolor': 'Theme Color',
    // 'app.setting.navigationmode': 'Navigation Mode',
    // 'app.setting.content-width': 'Content Width',
    // 'app.setting.fixedheader': 'Fixed Header',
    // 'app.setting.fixedsidebar': 'Fixed Sidebar',
    // 'app.setting.multitab': 'View multitab',
    // 'app.setting.sidemenu': 'Side Menu Layout',
    // 'app.setting.topmenu': 'Top Menu Layout',
    // 'app.setting.content-width.fixed': 'Fixed',
    // 'app.setting.content-width.fluid': 'Fluid',
    // 'app.setting.othersettings': 'Other Settings',
    // 'app.setting.weakmode': 'Weak Mode',
    // 'app.setting.copy': 'Copy Setting',
    // 'app.setting.loading': 'Loading theme',
    // 'app.setting.copyinfo': 'copy success，please replace defaultSettings in src/config/defaultSettings.js',
    // 'app.setting.production.hint': 'Setting panel shows in development environment only, please manually modify',
    // 'app.setting.themecolor.daybreak': 'Daybreak Blue',
    // 'app.setting.themecolor.dust': 'Dust Red',
    // 'app.setting.themecolor.volcano': 'Volcano',
    // 'app.setting.themecolor.sunset': 'Sunset Orange',
    // 'app.setting.themecolor.cyan': 'Cyan',
    // 'app.setting.themecolor.green': 'Polar Green',
    // 'app.setting.themecolor.geekblue': 'Geek Blue',
    // 'app.setting.themecolor.purple': 'Golden Purple'
}
